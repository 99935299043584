import {
	Button,
	FormBuilder,
	FormBuilderProps,
	Typography,
} from "@app/components";
import { Accordion } from "@app/components/accordion";
import { CustomLoader } from "@app/components/custom-loader";
import { FadeIn } from "@app/components/fade-in";
import { SignedInLayout } from "@app/components/signed-in-layout";

import { ApiErrors } from "@app/components/api-errors";
import type { ViewProperties } from "./properties";

import { MoreInfoTooltip } from "@app/components/more-info-tooltip";
import { FiChevronLeft, FiInfo } from "react-icons/fi";

import { TypeOptions } from "./type-options";

import "./add-edit-recipients.css";
import { WarningNote } from "@app/components/warning-note";
import { hasRecipientWarningNote } from "@app/constants/feature-flags";
import { useLocalStorage } from "@app/hooks/use-local-storage";
import { MappedReasons } from "@app/services";
import { ReactNode } from "react";
import { Control, FieldErrors, UseFormTrigger } from "react-hook-form";
import styles from "./index.module.css";

export const AddEditRecipientsView = (props: {
	addressFormInputs: FormBuilderProps.FormInputProps[][];
	apiErrors?: ReactNode[];
	detailsFormInputs: FormBuilderProps.FormInputProps[][];
	errors?: FieldErrors<any>;
	formControl: Control<any, any>;
	isEdit: boolean;
	isIndividual: boolean;
	isValid: boolean;
	isDirty: boolean;
	isSubmitting?: boolean;
	recipientTypeOptions: {
		type: string;
		label: string;
		icon: string;
	}[];
	loading?: boolean;
	mappedReasons?: MappedReasons;
	recipientType?: string;
	title: string;
	trigger: UseFormTrigger<any>;
	onBack: () => void;
	onCancel: () => void;
	onSelectRecipientType: (recipientType: string) => void;
}) => {
	const [showWarning, setShowWarning] = useLocalStorage("show-warning", true);

	const hasWarningNote =
		!props.isEdit &&
		showWarning &&
		props.recipientType &&
		hasRecipientWarningNote;
	return (
		<SignedInLayout
			footer={
				<div className={styles.footer}>
					<Button
						className={styles.footerButton}
						onClick={props.onCancel}
						variant="secondary"
					>
						Back
					</Button>
					<Button
						className={styles.footerButton}
						type="submit"
						disabled={!props.recipientType || props.isSubmitting}
					>
						Save
					</Button>
				</div>
			}
		>
			<div className={styles.container}>
				<div className={styles.header}>
					<Button
						className={styles.backButton}
						variant="tertiary"
						size="sm"
						onClick={props.onBack}
					>
						<FiChevronLeft color="inherit" size={20} />
						Back to recipients
					</Button>
					<h2 className={styles.title}>
						{props.isEdit ? "Edit recipient" : "New recipient"}
					</h2>
					<p className={styles.description}>
						{props.isEdit ? (
							<>
								Update the account details of a recipient.
								<br />
								Note that any future transactions to the recipient will be sent
								to the latest account details provided.
							</>
						) : (
							<>
								Conveniently store recipient bank details for your cross-border
								payments.
								<br />
								Please ensure that accurate details are provided to avoid
								misplaced funds.
							</>
						)}
					</p>
				</div>
				<div className={styles.typeContainer}>
					<div className={styles.typeTitle}>
						<p>What type of recipient is this?</p>
						<MoreInfoTooltip
							hasIcon
							name="Not sure?"
							maxWidth={300}
							indicator={
								<div className="flex flex-none gap-2 px-0 align-middle">
									<FiInfo size={22} color="#56a7a2" />
									<Typography
										theme="textMd"
										className={"font-medium text-teal-550"}
									>
										Not sure?
									</Typography>
								</div>
							}
						>
							To add a recipient that's a Trust, NPO, or any other recipient
							type not listed here, please select "Another company"
						</MoreInfoTooltip>
					</div>
					<TypeOptions
						value={props.recipientType}
						onChange={(value) => props.onSelectRecipientType(value)}
						options={props.recipientTypeOptions}
					/>
				</div>
				{hasWarningNote && (
					<WarningNote
						title="Verify recipient details"
						description="Fraudsters can impersonate others, so we recommend verifying recipient details telephonically."
					>
						<Button
							href="https://futureforex.co.za/forex-faq/#how-do-i-verify-a-recipient"
							className={styles.howToVerify}
							inline
							variant="tertiary"
						>
							How do I verify a recipient?
						</Button>
						<div className={styles.divider} />
						<button
							type="button"
							className={styles.removeNote}
							onClick={() => setShowWarning(false)}
						>
							Don’t show this again
						</button>
					</WarningNote>
				)}
				<div className={styles.card}>
					<Accordion
						className="add-edit-accordion"
						isDisabled={props.recipientType === undefined}
						itemIndex={props.recipientType === undefined ? -1 : 0}
						stayOpen={props.recipientType !== undefined}
						header="Recipient Details"
					>
						<FormBuilder
							formTitle={props.title as string}
							errors={props.errors}
							mappedReasons={props.mappedReasons}
							formInputs={props.detailsFormInputs}
							formControl={props.formControl}
						/>
					</Accordion>
				</div>
				<div className={styles.card}>
					<Accordion
						className="add-edit-accordion"
						isDisabled={props.recipientType === undefined}
						itemIndex={props.recipientType === undefined ? -1 : 0}
						stayOpen={props.recipientType !== undefined}
						header="Recipient Address"
					>
						<FormBuilder
							formTitle={props.title as string}
							errors={props.errors}
							mappedReasons={props.mappedReasons}
							formInputs={props.addressFormInputs}
							formControl={props.formControl}
						/>
					</Accordion>
				</div>
			</div>
			<FadeIn show={props.apiErrors && props.apiErrors.length > 0}>
				<ApiErrors errors={props.apiErrors} />
			</FadeIn>
			{props.loading && <CustomLoader />}
		</SignedInLayout>
	);
};
