import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { Tag } from "@app/components/tag";
import { pathParams, paths } from "@app/constants/paths";
import {
	type RecipientTransaction,
	type TransactionDirection,
} from "@app/entities";
import {
	useDeleteRecipient,
	useSetRecipientId,
	useShowToast,
} from "@app/helpers";
import type { RootState } from "@app/redux";
import { capitaliseFirstLetter } from "@app/utils";

import { dateFormats } from "@app/constants/date-formats";
import {
	tempCurrenciesMapping,
	useCurrencies,
} from "@app/hooks/use-currencies";
import {
	tempLatestRecipientTransactionMapping,
	useLatestRecipientTransactions,
} from "@app/hooks/use-latest-recipient-transactions";
import { tempMapRecipient, useRecipient } from "@app/hooks/use-recipient";
import { toDayjs } from "@app/lib/date";
import type { ViewRecipientTableModel } from "./models/view-recipient-table-model";
import { ViewButton } from "./view-button";
import { ViewRecipientView } from "./view-recipient-view";

const ViewRecipient = () => {
	const { deleteRecipientLoading, setRecipientNicknameLoading } = useSelector(
		(rootState: RootState) => rootState.recipients,
	);

	const [deleteRecipient] = useDeleteRecipient();
	const [showToast] = useShowToast();
	const [setRecipientId] = useSetRecipientId();

	const { data: currencies } = useCurrencies();
	const navigate = useNavigate();

	const params = useParams();

	const paramId = params[pathParams.id];

	const { data: latestTransactions, isLoading: isLatestTransactionsLoading } =
		useLatestRecipientTransactions(paramId, {
			limit: 5,
		});

	const { data, isLoading: isRecipientLoading, mutate } = useRecipient(paramId);

	const [recipient, setRecipient] = useState<
		ReturnType<typeof tempMapRecipient>
	>({
		firstName: "",
		lastName: "",
		companyName: "",
		nickname: "",
		entityType: "",
		currencies: "",
		ordering: "-date",
		bankDetails: {
			bankName: "",
			country: "",
			accountNumber: "",
			iban: "",
			swiftCode: "",
			routingNumber: "",
			sortCode: "",
			branch: "",
		},
		address: {
			addressLine1: "",
			addressLine2: "",
			city: "",
			province: "",
			country: "",
			postalCode: "",
			combinedStr: "",
		},
	});
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [openNicknameModal, setOpenNicknameModal] = useState(false);

	const isLoading = !!(
		deleteRecipientLoading ||
		isLatestTransactionsLoading ||
		isRecipientLoading ||
		setRecipientNicknameLoading
	);

	const onClickAddOrEdit = () => setOpenNicknameModal(true);

	const onCloseNicknameModal = (newNickname?: boolean) => {
		setOpenNicknameModal(false);
		if (newNickname && paramId) {
			mutate();
		}
	};

	const onClickDelete = () => setOpenDeleteModal(true);
	const onCloseDeleteModal = () => setOpenDeleteModal(false);

	const onDeleteRecipient = () => {
		if (paramId) {
			deleteRecipient(+paramId, (response) => {
				if (response === 200) {
					onCloseDeleteModal();
					navigate(paths().recipients);
					showToast("Recipient successfully removed", "success");
				}
			});
		}
	};

	const onEditRecipient = () => {
		if (paramId) {
			navigate(paths().editRecipient(+paramId));
		}
	};

	const onBack = () => {
		navigate(paths().recipients);
	};

	const onViewAllTransactions = (recipientId?: number) => {
		setRecipientId(recipientId);
		navigate(paths().transactions());
	};

	const mapToTableRowModel = (
		x: RecipientTransaction,
	): ViewRecipientTableModel => {
		const paymentType = x.paymentType.toLowerCase() as TransactionDirection;

		const paymentTypeStyle = twMerge(
			paymentType === "send"
				? "border border-blue-200 bg-blue-50 w-full max-w-20 flex justify-center"
				: "border border-purple-200 bg-purple-50 w-full max-w-20 flex justify-center",
		);

		const paymentTypeIconPlacement = paymentType === "send" ? "right" : "left";

		const paymentTypeIcon =
			paymentType === "send" ? "FiArrowRight" : "FiArrowLeft";

		return {
			...x,
			date:
				x.date !== null
					? toDayjs(x.date, dateFormats.reverseIso8601).format(
							dateFormats.paddedDayShortMonthYear,
						)
					: "-",
			fxAmount: x.fxAmount,
			fxAmountDisplay: (
				<>
					{x.fxAmount !== "" ? (
						x.fxAmount
					) : (
						<div className="view-recipient-empty-table-cell">-</div>
					)}
				</>
			),
			tablePaymentType: (
				<Tag
					icon={paymentTypeIcon}
					iconPlacement={paymentTypeIconPlacement}
					className={paymentTypeStyle}
					tagStyle={"custom"}
					text={capitaliseFirstLetter(x.paymentType)}
				/>
			),
			transactionStatus: (
				<Tag
					iconPlacement="left"
					tagStyle={
						x.status.toLowerCase() === "complete" ? "complete" : "inProgress"
					}
					text={x.status}
				/>
			),
			view: <ViewButton transactionId={x.id} />,
			zarAmount: x.zarAmount,
			zarAmountDisplay: (
				<>
					{x.zarAmount !== "" ? (
						x.zarAmount
					) : (
						<div className="view-recipient-empty-table-cell">-</div>
					)}
				</>
			),
		};
	};

	useEffect(() => {
		if (data) {
			setRecipient(tempMapRecipient(data));
		}
	}, [data]);

	return (
		<ViewRecipientView
			currencies={currencies?.currency_mapping.map(tempCurrenciesMapping)}
			latestTransactions={latestTransactions?.items?.map((transaction) =>
				mapToTableRowModel(tempLatestRecipientTransactionMapping(transaction)),
			)}
			loading={isLoading}
			openDeleteModal={openDeleteModal}
			openNicknameModal={openNicknameModal}
			recipient={recipient}
			recipientId={paramId ? +paramId : undefined}
			onBack={onBack}
			onClickAddOrEdit={onClickAddOrEdit}
			onClickDelete={onClickDelete}
			onCloseNicknameModal={onCloseNicknameModal}
			onCloseDeleteModal={onCloseDeleteModal}
			onSendFunds={() => {
				navigate(paths().sendReceiveFunds(paramId ? +paramId : undefined));
			}}
			onEditRecipient={onEditRecipient}
			onDeleteRecipient={onDeleteRecipient}
			onViewAllTransactions={onViewAllTransactions}
		/>
	);
};

export default ViewRecipient;
