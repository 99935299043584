import { Button } from "@app/components/button";
import { CustomLoader } from "@app/components/custom-loader";
import { IconButton } from "@app/components/icon-button";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { pathParams } from "@app/constants/paths";
import { useSetProfilePicture, useShowToast } from "@app/helpers";
import { useMediaQuery } from "@app/hooks/use-media-query";
import type { GenericFailureResponse, MappedReasons } from "@app/services";
import { FiTrash2 } from "react-icons/fi";

import { DocumentUploadModal } from "@app/components/document-upload-modal";
import { ProfilePicture } from "@app/components/profile-picture";
import { useClientProfile } from "@app/hooks/use-client-profile";
import { useProfilePicture } from "@app/hooks/use-profile-picture";
import { useUserSettings } from "@app/hooks/use-user-settings";
import { getMappedReasons } from "@app/utils";
import { Title } from "../onboarding/title";
import { ChangeEmailAddressModal } from "./change-email-address-modal";
import { ChangePasswordModal } from "./change-password-modal";
import styles from "./index.module.css";
import { NewEmailNotVerfiedWarning } from "./new-email-not-verified-warning";
import { RemoveProfilePictureModal } from "./remove-profile-picture-modal";
import { useUserProfile } from "./use-user-profile";
import { VerificationLinkSentModal } from "./verification-link-sent-modal";

interface ChangePasswordForm {
	currentPassword?: string;
	newPassword?: string;
	confirmNewPassword?: string;
}

const PenIcon = () => (
	<svg
		role="presentation"
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="25"
		viewBox="0 0 24 25"
		fill="none"
	>
		<path
			d="M18 10.096L14 6.09601M2.5 21.596L5.88437 21.22C6.29786 21.174 6.5046 21.1511 6.69785 21.0885C6.86929 21.033 7.03245 20.9546 7.18289 20.8554C7.35245 20.7436 7.49955 20.5965 7.79373 20.3023L21 7.09601C22.1046 5.99144 22.1046 4.20058 21 3.09601C19.8955 1.99144 18.1046 1.99144 17 3.096L3.79373 16.3023C3.49955 16.5965 3.35246 16.7436 3.24064 16.9131C3.14143 17.0636 3.06301 17.2267 3.00751 17.3982C2.94496 17.5914 2.92198 17.7982 2.87604 18.2116L2.5 21.596Z"
			stroke="#56A7A2"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export const UserSettingsContent = ({
	variant = "default",
}: {
	variant?: "default" | "onboarding";
}) => {
	const [hasCheckedEmailChange, sethasCheckedEmailChange] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const isMobile = useMediaQuery();
	const [showVerificationLinkSentModal, setShowVerificationLinkSentModal] =
		useState(false);

	const {
		data: userProfile,
		isLoading: isUserProfileLoading,
		mutate: userProfileMutate,
	} = useUserProfile();

	const { data: profilePicture, mutate: mutateProfilePicture } =
		useProfilePicture();
	const { data: clientProfile, isLoading: isClientProfileLoading } =
		useClientProfile();
	const { changeEmail, changePassword, sendChangeEmailLink } =
		useUserSettings();

	const [setProfilePicture] = useSetProfilePicture();
	const [showToast] = useShowToast();

	const params = useParams();
	const paramToken = params[pathParams.token];

	const isLoading =
		isSubmitting || isUserProfileLoading || isClientProfileLoading;

	const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
	const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
	const [showChangeProfilePictureModal, setShowChangeProfilePictureModal] =
		useState(false);
	const [showRemoveProfilePictureModal, setShowRemoveProfilePictureModal] =
		useState(false);

	const [emailMappedReasons, setEmailMappedReasons] = useState<MappedReasons>();
	const [passwordMappedReasons, setPasswordMappedReasons] =
		useState<MappedReasons>();

	const onToggleChangeEmailModal = () =>
		setShowChangeEmailModal(!showChangeEmailModal);

	const onToggleChangePasswordModal = () =>
		setShowChangePasswordModal((current) => !current);

	const onToggleChangeProfilePictureModal = () =>
		setShowChangeProfilePictureModal(!showChangeProfilePictureModal);
	const onToggleRemoveProfilePictureModal = () =>
		setShowRemoveProfilePictureModal(!showRemoveProfilePictureModal);

	const onChangePassword = async (passwords: ChangePasswordForm) => {
		try {
			if (!passwords.currentPassword || !passwords.newPassword) return;
			setPasswordMappedReasons(undefined);
			setSubmitting(true);
			await changePassword(passwords.currentPassword, passwords.newPassword);
			setShowChangePasswordModal(false);
			showToast("Password successfully changed", "success");
		} catch (error: any) {
			const mappedReasons = getMappedReasons(
				error.data as GenericFailureResponse,
			);
			setPasswordMappedReasons(mappedReasons);
		} finally {
			setSubmitting(false);
		}
	};

	const onRemoveProfilePicture = () => {
		setProfilePicture(
			{
				profilePicture: undefined,
				onUploadProgress: () => {},
			},
			(errors?: string[], mappedReasons?: MappedReasons) => {
				if (!errors || (errors.length === 0 && !mappedReasons)) {
					onToggleRemoveProfilePictureModal();
				}
			},
		);
	};

	const handleSendEmailLink = async (email: string, isResend = false) => {
		try {
			setEmailMappedReasons(undefined);
			setSubmitting(true);
			await sendChangeEmailLink(email);
			setShowChangeEmailModal(false);
			if (!isResend) {
				setShowVerificationLinkSentModal(true);
			}
			userProfileMutate();
		} catch (error: any) {
			const mappedReasons = getMappedReasons(
				error.data as GenericFailureResponse,
			);
			setEmailMappedReasons(mappedReasons);
			return false;
		} finally {
			setSubmitting(false);
		}
		return true;
	};

	const onUpload = (
		index: number,
		file: File | undefined,
		onSetUploadComplete: (index: number, id?: number | string) => void,
		onSetUploadProgress: (progressEvent: ProgressEvent) => void,
		onUploadError: (error: string, index: number) => void,
	) => {
		if (file) {
			setProfilePicture(
				{ profilePicture: file, onUploadProgress: onSetUploadProgress },
				(errors?: any[], mappedReasons?: MappedReasons) => {
					if (!errors || (errors.length === 0 && !mappedReasons)) {
						onSetUploadComplete(index);
						mutateProfilePicture();
						return;
					}
					const errorMessage = errors[0]?.[0]?.msg ?? "Upload failed";
					onUploadError(errorMessage, index);
				},
			);
		}
	};

	const handleChangeEmail = useCallback(
		async (paramToken: string) => {
			sethasCheckedEmailChange(true);
			try {
				await changeEmail(paramToken);
				showToast("Email successfully changed", "success");
				userProfileMutate();
			} catch {
				showToast("Failed to change email", "error");
			}
		},
		[changeEmail, showToast, userProfileMutate],
	);

	useEffect(() => {
		if (paramToken && !hasCheckedEmailChange) {
			handleChangeEmail(paramToken);
		}
	}, [paramToken, handleChangeEmail, hasCheckedEmailChange]);

	return (
		<>
			<div data-variant={variant} className={styles.container}>
				{variant === "onboarding" ? (
					isMobile ? null : (
						<Title>Settings</Title>
					)
				) : (
					<h2 className={styles.title}>Settings</h2>
				)}

				<div className={styles.pictureRow}>
					<div className={styles.pictureRowInner}>
						<ProfilePicture size={100} src={profilePicture?.base64} />
						<div className={styles.pictureDescription}>
							<p>Profile Picture</p>
							<p className={styles.pictureFormat}>PNG, JPEG under 10MB</p>
						</div>
					</div>

					<div className={styles.pictureActions}>
						<Button
							disabled={isLoading}
							onClick={() => {
								onToggleChangeProfilePictureModal();
							}}
						>
							{clientProfile?.profile_picture_url
								? "Change picture"
								: "Add picture"}
						</Button>
						<IconButton
							disabled={isLoading}
							onClick={onToggleRemoveProfilePictureModal}
						>
							<FiTrash2 size={24} />
						</IconButton>
					</div>
				</div>
				<div className={styles.divider} />

				<div className={styles.settingsSection}>
					<h3 className={styles.subtitle}>User Settings</h3>

					<div className={styles.settingsRow}>
						<div>
							<p className={styles.settingsLabel}>Email</p>
							<p className={styles.settingsValue}>
								{userProfile?.pending_email_change
									? userProfile?.pending_email_change
									: userProfile?.email}
							</p>
						</div>
						{isMobile ? (
							<IconButton onClick={onToggleChangeEmailModal}>
								<PenIcon />
							</IconButton>
						) : (
							<Button
								className={styles.settingsButton}
								variant="secondary"
								onClick={onToggleChangeEmailModal}
							>
								Change email
							</Button>
						)}
					</div>

					{userProfile?.pending_email_change && (
						<NewEmailNotVerfiedWarning
							email={userProfile.pending_email_change}
							onResend={() =>
								handleSendEmailLink(userProfile.pending_email_change, true)
							}
						/>
					)}

					<div className={styles.settingsRow}>
						<div>
							<p className={styles.settingsLabel}>Password</p>
							<p className={styles.settingsValue}>••••••••••••••</p>
						</div>
						{isMobile ? (
							<IconButton onClick={onToggleChangePasswordModal}>
								<PenIcon />
							</IconButton>
						) : (
							<Button
								className={styles.settingsButton}
								onClick={onToggleChangePasswordModal}
								variant="secondary"
							>
								Change password
							</Button>
						)}
					</div>
				</div>
			</div>

			<ChangeEmailAddressModal
				isOpen={showChangeEmailModal}
				onClose={onToggleChangeEmailModal}
				onSendVerification={handleSendEmailLink}
				mappedReasons={emailMappedReasons}
			/>
			<ChangePasswordModal
				isOpen={showChangePasswordModal}
				onClose={onToggleChangePasswordModal}
				onSave={onChangePassword}
				mappedReasons={passwordMappedReasons}
			/>
			{showChangeProfilePictureModal && (
				<DocumentUploadModal
					multiple={false}
					maxFiles={1}
					showSingleButton
					onClose={() => {
						onToggleChangeProfilePictureModal();
					}}
					onUpload={onUpload}
					headingMessage="Upload image"
					bodyMessage="PNG or JPEG under 10MB"
					onConfirmUpload={onToggleChangeProfilePictureModal}
				/>
			)}
			{showRemoveProfilePictureModal && (
				<RemoveProfilePictureModal
					onClose={onToggleRemoveProfilePictureModal}
					onRemove={onRemoveProfilePicture}
					isOpen={showRemoveProfilePictureModal}
				/>
			)}
			<VerificationLinkSentModal
				email={userProfile?.pending_email_change}
				isOpen={showVerificationLinkSentModal}
				onClose={() => setShowVerificationLinkSentModal(false)}
			/>

			{isLoading && <CustomLoader />}
		</>
	);
};
