import { twMerge } from "tailwind-merge";

import SortDown from "@app/assets/images/sort-down.svg";
import { Button } from "@app/components/button";
import { CustomLoader } from "@app/components/custom-loader";
import { IconButton } from "@app/components/icon-button";
import { SignedInLayout } from "@app/components/signed-in-layout";
import { Table } from "@app/components/table";
import { Typography } from "@app/components/typography";
import { useMediaQuery } from "@app/hooks/use-media-query";

import { Footer } from "./footer";
import type { Properties } from "./properties";
import { TransactionsSection } from "./transactions-section";

import { FiChevronLeft, FiEdit2, FiPlus, FiTrash2 } from "react-icons/fi";
import { RemoveRecipientModal } from "../recipients/remove-recipient-modal";
import "./view-recipient.css";

import { CurrencyTags } from "../../components/payment-details-block/currency-tags";
import { EditNicknameModal } from "./edit-nickname-modal";
import styles from "./index.module.css";

export const ViewRecipientView = (props: Properties) => {
	const isMobile = useMediaQuery();
	const currencies = props.recipient.currencies?.split(",");

	const entityType =
		props.recipient.entityType === "individual" ? "Individual" : "Business";

	return (
		<SignedInLayout title="View Recipient" hasBackButton>
			<div className="view-recipient-container p-6">
				{!isMobile && (
					<>
						<div className="view-recipient-panel">
							<Button
								inline
								noPadding
								size="sm"
								variant="tertiary"
								onClick={props.onBack}
							>
								<FiChevronLeft color="#56a7a2" size={20} />
								Back to recipients
							</Button>
						</div>
					</>
				)}

				<div className="view-recipient-card">
					<div className={styles.header}>
						<h3 className={styles.title}>
							{props.recipient.entityType === "individual"
								? `${props.recipient.firstName} ${props.recipient.lastName}`
								: props.recipient.companyName}{" "}
						</h3>
						<div className={styles.buttons}>
							{isMobile ? (
								<>
									<IconButton onClick={props.onEditRecipient}>
										<FiEdit2 color="#56a7a2" size={24} />
									</IconButton>
									<IconButton onClick={props.onClickDelete}>
										<FiTrash2 color="#56a7a2" size={24} />
									</IconButton>
								</>
							) : (
								<>
									<Button onClick={props.onSendFunds}>Send funds</Button>
									<Button onClick={props.onEditRecipient} variant="secondary">
										Edit
									</Button>
								</>
							)}
						</div>
					</div>
					<div className="view-recipient-nickname-container">
						{props.recipient.nickname && props.recipient.nickname !== "" ? (
							<div className="flex flex-row items-center gap-2">
								{isMobile ? (
									<Typography
										theme="textSm"
										className={
											"view-recipient-nickname-text-responsive m-0 w-fit font-semibold"
										}
									>
										{props.recipient.nickname}
									</Typography>
								) : (
									<Typography
										theme="textLg"
										className="view-recipient-nickname-text"
									>
										{props.recipient.nickname}
									</Typography>
								)}

								<Button
									onClick={props.onClickAddOrEdit}
									variant="tertiary"
									size="sm"
									noPadding
									inline
								>
									<FiEdit2 color="#56a7a2" size={20} />
									Edit nickname
								</Button>
							</div>
						) : (
							<Button
								variant="tertiary"
								inline
								noPadding
								onClick={props.onClickAddOrEdit}
							>
								<FiPlus color="#56a7a2" size={20} />
								Add nickname
							</Button>
						)}
					</div>
					<div className={styles.tagContainer}>
						{props.recipient.entityType && (
							<div className={styles.tag}>{entityType}</div>
						)}
						{props.recipient.countryLabel && (
							<div className={styles.tag}>{props.recipient.countryLabel}</div>
						)}
						<CurrencyTags
							className={styles.currencyTags}
							currencies={currencies}
						/>
					</div>
					<div className="view-recipient-card-separator" />
					<div className={styles.content}>
						<div className={styles.items}>
							<div className="w-full">
								<Typography
									theme="textSm"
									className="view-recipient-subheading"
								>
									Bank
								</Typography>
								<Typography theme="textLg" className="view-recipient-text">
									{props.recipient.bankDetails?.bankName}
								</Typography>
							</div>
							<div className="w-full">
								<Typography
									theme="textSm"
									className="view-recipient-subheading"
								>
									{props.recipient.bankDetails?.iban
										? "IBAN"
										: "Account Number"}
								</Typography>
								<Typography theme="textLg" className="view-recipient-text">
									{props.recipient.bankDetails?.iban
										? props.recipient.bankDetails?.iban
										: props.recipient.bankDetails?.accountNumber}
								</Typography>
							</div>
							<div className="w-full">
								<Typography
									theme="textSm"
									className="view-recipient-subheading"
								>
									SWIFT Code
								</Typography>
								<Typography theme="textLg" className="view-recipient-text">
									{props.recipient.bankDetails?.swiftCode}
								</Typography>
							</div>
						</div>
						<div className="w-full">
							<div>
								<Typography
									theme="textSm"
									className="view-recipient-subheading"
								>
									Address
								</Typography>
								<Typography theme="textLg" className="view-recipient-text">
									{props.recipient.address?.combinedStr}
								</Typography>
							</div>
						</div>
					</div>
				</div>

				<div className="w-full">
					<div className="text-left">
						{isMobile ? (
							<>
								<Typography theme="displayXs" className={"mb-4 font-display"}>
									Latest transactions
								</Typography>
								<TransactionsSection {...props} />
								<Button
									centered
									variant="secondary"
									className="mb-6"
									onClick={() => {
										props.onViewAllTransactions(props.recipientId);
									}}
								>
									View all transactions
								</Button>
							</>
						) : (
							<>
								<Typography
									theme="displaySm"
									className={twMerge("font-display", "pb-6")}
								>
									Latest transactions
								</Typography>
								{(!props.latestTransactions ||
									props.latestTransactions.length === 0) && (
									<div className="view-recipient-no-transactions">
										No transactions to display
									</div>
								)}
								{props.latestTransactions &&
									props.latestTransactions.length > 0 && (
										<div className="latest-transactions-table-container">
											<Table
												lazy
												totalRecords={5}
												defaultSortField={"date"}
												sortOrder={-1}
												emptyMessage="No transactions to display"
												columns={[
													{
														field: "date",
														header: (
															<div className="flex flex-row gap-2">
																<span>Date</span>
																<img src={SortDown} alt="" />
															</div>
														),
													},
													{
														field: "transactionStatus",
														header: "Status",
														alignHeader: "center",
														className: "view-recipient-tag-col",
													},
													{
														field: "zarAmountDisplay",
														header: "ZAR amount",
													},
													{
														field: "tablePaymentType",
														header: "Type",
														alignHeader: "center",
														className: "view-recipient-tag-col",
													},
													{
														field: "fxAmountDisplay",
														header: "FX amount",
													},
													{
														field: "view",
														header: "",
													},
												]}
												values={props.latestTransactions}
												stripedRows
											/>
											<Button
												centered
												variant="secondary"
												onClick={() => {
													props.onViewAllTransactions(props.recipientId);
												}}
											>
												View all transactions
											</Button>
										</div>
									)}
							</>
						)}
					</div>
				</div>

				{props.openNicknameModal && (
					<EditNicknameModal
						isEditNickname={!!props.recipient.nickname}
						nickname={props.recipient.nickname}
						recipientId={props.recipientId}
						onNicknameSaved={() => props.onCloseNicknameModal(true)}
						onCancel={props.onCloseNicknameModal}
					/>
				)}
				{props.openDeleteModal && (
					<RemoveRecipientModal
						isOpen
						onClose={props.onCloseDeleteModal}
						onRemove={props.onDeleteRecipient}
					/>
				)}
				{isMobile && !props.openNicknameModal && (
					<Footer
						onClickDelete={props.onClickDelete}
						onSendFunds={props.onSendFunds}
					/>
				)}
			</div>
			{props.loading && <CustomLoader />}
		</SignedInLayout>
	);
};
